import { deleteField } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { selectedEnquiryOwnerAnalytics } from 'lib/enquiries/actions';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { EnquiriesActionTypes, IUpdateEnquiryOwner } from '../action-types';

export const updateOwnerEpic = (action$: Observable<IUpdateEnquiryOwner>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.UPDATE_OWNER),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const {
        supplier: {
          supplier: { id: supplierId },
        },
      } = state;
      const { enquiryId, ownerId } = payload || {};

      const getPromise = async () => {
        if (!enquiryId) throw new Error('Enquiry/Owner not passed');

        const supplier = Suppliers._.getById(supplierId);
        return supplier.Weddings.getById(enquiryId).set({
          owner: ownerId || deleteField(),
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() => of(selectedEnquiryOwnerAnalytics({ enquiryId, ownerId }))),
        catchError((error: Error) => of(appError({ error, feature: 'UPDATE_OWNER' }))),
      );
    }),
  );
