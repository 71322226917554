import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { supplierEnquiryPropertiesGeneric } from 'lib/enquiries/analytics';
import { findEnquiryById } from 'lib/enquiries/selectors';
import {
  ClickedPayForEnquiryCreditsAction,
  ClickedUnlockEnquiryAction,
  ClosedBuyEnquiriesCallbackPopupAction,
  PayPerEnquiryAnalyticsActionTypes,
  TriggeredBuyEnquiriesCallbackPopupAction,
  TriggeredBuyEnquiriesPopupAnalyticsAction,
  TriggeredUnlockEnquiryPopupAction,
} from 'lib/pay-per-enquiry/analytics/action-types';
import { getEnquiryBalanceNumber, getIsLocked } from 'lib/pay-per-enquiry/selectors';
import { getSupplierEmail } from 'lib/supplier/selectors';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function payPerEnquiryAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const state = getState();
  const { track } = bridebookAnalytics.getMethods('CMS');

  const supplierProperties = supplierPropertiesGeneric(state);
  const supplierEmail = getSupplierEmail(state);
  const enquiryCreditBalance = getEnquiryBalanceNumber(state);

  switch (action.type) {
    case PayPerEnquiryAnalyticsActionTypes.TRIGGERED_BUY_ENQUIRIES_POPUP: {
      const {
        payload: { touchpointLocation },
      } = action as TriggeredBuyEnquiriesPopupAnalyticsAction;

      track({
        event: 'Triggered Buy more enquiry credits pop-up - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        enquiryCreditBalance,
        supplierEmail,
        buyMoreEnquiryCreditsLocation: touchpointLocation,
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLICKED_PAY_FOR_ENQUIRY_CREDITS: {
      const {
        payload: { priceId },
      } = action as ClickedPayForEnquiryCreditsAction;

      track({
        event: 'Clicked Pay for Enquiry Credits - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        enquiryCreditBalance,
        supplierEmail,
        selectedEnquiryPackage: priceId,
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLOSED_BUY_ENQUIRIES_POPUP: {
      track({
        event: 'Closed Buy more enquiry credits pop up - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        supplierEmail,
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.TRIGGERED_BUY_ENQUIRIES_CALLBACK_POPUP: {
      const {
        payload: { isSuccessful, id, plan },
      } = action as TriggeredBuyEnquiriesCallbackPopupAction;

      const paymentState = isSuccessful ? 'success' : 'issue';

      track({
        event: `Triggered Enquiry credit purchase ${paymentState} pop up - CMS`,
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        stripeId: id,
        stripePlan: plan,
        enquiryCreditBalance,
        supplierEmail,
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLOSED_BUY_ENQUIRIES_CALLBACK_POPUP: {
      const {
        payload: { isSuccessful },
      } = action as ClosedBuyEnquiriesCallbackPopupAction;

      if (!isSuccessful) {
        track({
          event: 'Closed Enquiry credit purchase issue pop up - CMS',
          type: 'supplierProfile',
          category: 'Pay Per Enquiry',
          supplierEmail,
          ...supplierProperties,
        });
      }
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLICKED_TRY_AGAIN_CALLBACK_POPUP: {
      track({
        event: 'Clicked Try Again to purchase enquiry credit - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        supplierEmail,
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.TRIGGERED_UNLOCK_ENQUIRY_POPUP: {
      const {
        payload: { enquiryId },
      } = action as TriggeredUnlockEnquiryPopupAction;
      const enquiry = findEnquiryById(state, enquiryId);

      track({
        event: 'Triggered Unlock enquiry pop up - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        supplierEmail,
        ...(enquiry && supplierEnquiryPropertiesGeneric({ enquiry, isLocked: true })),
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLICKED_UNLOCK_ENQUIRY: {
      const {
        payload: { enquiryId },
      } = action as ClickedUnlockEnquiryAction;
      const enquiry = findEnquiryById(state, enquiryId);

      track({
        event: 'Clicked Unlock enquiry - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        supplierEmail,
        enquiryCreditBalance,
        ...(enquiry && supplierEnquiryPropertiesGeneric({ enquiry, isLocked: true })),
        ...supplierProperties,
      });
      break;
    }
    case PayPerEnquiryAnalyticsActionTypes.CLOSED_UNLOCK_ENQUIRY_POPUP: {
      const {
        payload: { enquiryId },
      } = action as ClickedUnlockEnquiryAction;
      const enquiry = findEnquiryById(state, enquiryId);
      const isLocked = getIsLocked(getState());

      track({
        event: 'Closed Unlock enquiry pop up - CMS',
        type: 'supplierProfile',
        category: 'Pay Per Enquiry',
        supplierEmail,
        ...(enquiry && supplierEnquiryPropertiesGeneric({ enquiry, isLocked })),
        ...supplierProperties,
      });
      break;
    }
    default: {
      break;
    }
  }
}
