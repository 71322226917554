import { reverse } from 'ramda';
import { ISupplierWeddingManager } from '../supplier-wedding-manager';

export type ProgressValuesType =
  | 'read'
  | 'replied'
  | 'followedUp'
  | 'inDiscussion'
  | 'followedUpPostMeeting'
  | 'meetingScheduled'
  | 'callOrMeetingHeld'
  | 'dateOnHold'
  | 'booked';

export interface IProgressTrackerConfig {
  id: ProgressValuesType;
  name: string;
  tagStyle: string; // @TODO: This was `keyof IColorTypes`, but we don't want to import `@bridebook/ui` just for this.
  tagName?: string;
}

export const defaultProgressTrackerConfig: IProgressTrackerConfig[] = [
  {
    id: 'read',
    name: 'Read',
    tagStyle: 'grey',
  },
  {
    id: 'replied',
    name: 'Replied',
    tagStyle: 'purple',
  },
  {
    id: 'followedUp',
    name: 'Followed-Up',
    tagStyle: 'purple',
  },
  {
    id: 'inDiscussion',
    name: 'In discussion',
    tagStyle: 'purple',
  },
  {
    id: 'meetingScheduled',
    name: 'Meeting schedule',
    tagStyle: 'purple',
  },
  {
    id: 'callOrMeetingHeld',
    name: 'Call/Meeting Held',
    tagName: 'Call/Meeting',
    tagStyle: 'indigoCrush',
  },
  {
    id: 'followedUpPostMeeting',
    name: 'Followed-up post meeting',
    tagStyle: 'indigoCrush',
  },
  {
    id: 'dateOnHold',
    name: 'Date on Hold',
    tagStyle: 'indigoCrush',
  },
  {
    id: 'booked',
    name: 'Booked',
    tagStyle: 'teal120',
  },
];

// returns enquiry progress label
export const getEnquiryProgress = (
  enquiry: ISupplierWeddingManager,
  config = defaultProgressTrackerConfig,
) => {
  const currentProgress = reverse(config)
    .map((p) => (enquiry?.progress?.includes(p?.id) ? p : null))
    .filter(Boolean);

  return currentProgress[0];
};

/**
 * Convert an array of progress ids to an object with all statuses as keys and boolean values
 * @param progress
 */
export const mapProgressArrayToAnalyticsObject = (progress?: string[]) =>
  defaultProgressTrackerConfig.reduce((a, v) => {
    a[v.name] = Boolean(progress?.includes(v.id));
    return a;
  }, {} as Record<string, boolean>);

export default getEnquiryProgress;
