import { colors } from '@bridebook/ui';

export const TABS_CHART_HEIGHT = 235;

export enum ChartName {
  bookedSupplier = 'bookedSupplier',
  searchAppearances = 'searchAppearances',
  enquiryViews = 'enquiryViews',
  profileViews = 'profileViews',
  coupleBudgets = 'coupleBudgets',
  responseScores = 'responseScores',
  profileScore = 'profileScore',
  historicalRankings = 'historicalRankings',
  historicalRankingsCompetitors = 'historicalRankingsCompetitors',
}

export enum ChartGranularity {
  month = 'month',
  week = 'week',
}

export const COMPETITORS_CHARTS_COLORS = [
  colors.indigoCrush,
  colors.teal120,
  colors.peachRose120,
  colors.purple,
  colors.blushTangerine120,
];
