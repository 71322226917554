import { HubspotIds } from './types';

/**
 * Assignment of regions to Hubspot meeting IDs
 */
export const hubspotIdToRegions: Partial<Record<HubspotIds, string[]>> = {
  [HubspotIds.Test]: ['Test'],
  [HubspotIds.Ashley]: [
    'Aberdeen City',
    'Aberdeenshire',
    'Angus',
    'Argyll and Bute',
    'City of Edinburgh',
    'Clackmannanshire',
    'Dumfries and Galloway',
    'Dundee City',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'Falkirk',
    'Fife',
    'Flintshire',
    'Glasgow City',
    'Highland',
    'Inverclyde',
    'Midlothian',
    'Moray',
    'Na h-Eileanan an Iar',
    'North Ayrshire',
    'North Lanarkshire',
    'Orkney',
    'Perth and Kinross',
    'Renfrewshire',
    'Scottish Borders',
    'Shetland Islands',
    'South Ayrshire',
    'South Lanarkshire',
    'Stirling',
    'West Dunbartonshire',
    'West Lothian',
  ],
  [HubspotIds.Dylan]: [
    'Belfast',
    'County Antrim',
    'County Armagh',
    'County Down',
    'County Fermanagh',
    'County Londonderry',
    'County Tyrone',
  ],
  [HubspotIds.Katie]: [
    'Berkshire',
    'East Sussex',
    'Kent',
    'Norfolk',
    'Oxfordshire',
    'Suffolk',
    'Surrey',
    'West Sussex',
  ],
  [HubspotIds.Ram]: [
    'Cornwall',
    'Devon',
    'Dorset',
    'Hampshire',
    'Isle of Wight',
    'Rutland',
    'Somerset',
    'Wiltshire',
    'Buckinghamshire',
    'Cambridgeshire',
  ],
  [HubspotIds.Suraj]: [
    'Bristol',
    'Caerphilly',
    'Carmarthenshire',
    'Denbighshire',
    'Derbyshire',
    'Herefordshire',
    'Isle of Anglesey',
    'Leicestershire',
    'Newport',
    'Powys',
    'Rhondda Cynon Taf',
    'Shropshire',
    'South Yorkshire',
    'Staffordshire',
    'Warwickshire',
    'West Midlands',
    'West Yorkshire',
    'Worcestershire',
    'Wrexham',
  ],
  [HubspotIds.Ton]: [
    'Blaenau Gwent',
    'Bridgend',
    'Cardiff',
    'Ceredigion',
    'Cheshire',
    'Conwy',
    'County Durham',
    'Cumbria',
    'East Riding of Yorkshire',
    'Essex',
    'Greater Manchester',
    'Gwynedd',
    'Hertfordshire',
    'Lancashire',
    'Lincolnshire',
    'Merseyside',
    'Merthyr Tydfil',
    'Monmouthshire',
    'Neath Port Talbot',
    'North Yorkshire',
    'Northumberland',
    'Pembrokeshire',
    'Swansea',
    'Torfaen',
    'Tyne and Wear',
    'Vale of Glamorgan',
  ],
  [HubspotIds.Zahra]: [
    'Bedfordshire',
    'City of London',
    'Gloucestershire',
    'Greater London',
    'Northamptonshire',
    'Nottinghamshire',
  ],
};
