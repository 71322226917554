import { getI18n } from 'react-i18next';
import { TABS_CHART_HEIGHT } from '../constants';
import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const EnquiryViews = (): IStatisticsHighchartsConfig => {
  const i18n = getI18n();
  return getChartView({
    xAxisTitle: i18n.t('enquiryGraphs:config.enquiryViews.xAxisTitle'),
    yAxisTitle: i18n.t('enquiryGraphs:config.enquiryViews.yAxisTitle'),
    seriesName: i18n.t('enquiryGraphs:config.enquiryViews.seriesName'),
    height: TABS_CHART_HEIGHT,
    allowDecimals: false,
    // tickInterval: 50, // 10 for weekly // was not working as expected, need to revisit
  });
};

export default EnquiryViews;
