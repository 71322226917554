import {
  changeAuthEmail,
  changeAuthEmailError,
  changeAuthEmailForm,
  onAuth,
  redirectAfterAuth,
  resetPassword,
  resetPasswordError,
  signInError,
  signInWithPassword,
  signOut,
  signUp,
  signUpError,
} from 'lib/auth/actions';

export enum AuthActionTypes {
  SIGN_IN_WITH_PASSWORD = '[Auth] Sing in with password provider',
  SIGN_IN_WITH_PASSWORD_SUCCESS = '[Auth] Sing in with password provider success',
  SIGN_IN_SUCCESS = '[Auth] Sign in success',
  SIGN_IN_ERROR = '[Auth] Sign in error',
  SIGN_UP = '[Auth] Sign up start',
  SIGN_UP_SUCCESS = '[Auth] Sign up success',
  SIGN_UP_ERROR = '[Auth] Sign up error',
  RESET_PASSWORD = '[Auth] Request to reset a password from Firebase',
  RESET_PASSWORD_SUCCESS = '[Auth] Request to reset a password from Firebase success',
  RESET_PASSWORD_ERROR = '[Auth] Request to reset a password from Firebase error',
  CHANGE_AUTH_EMAIL = '[Auth] Change auth email',
  CHANGE_AUTH_EMAIL_SUCCESS = '[Auth] Change auth email success',
  CHANGE_AUTH_EMAIL_ERROR = '[Auth] Change auth email error',
  SET_AUTH_EMAIL_FORM_FIELD = '[Auth] Set auth email form field',
  ON_AUTH = '[Auth] on firebase auth state change',
  SIGN_OUT = '[Auth] Sign out',
  SIGN_OUT_SUCCESS = '[Auth] Sign out success',
  REDIRECT_AFTER_AUTH = '[Auth] Redirect after auth',
  REDIRECT_AFTER_AUTH_DONE = '[Auth] Redirect after authentication done',
  DECODE_COLLABORATOR_INVITE = '[Auth] Decode collaborator invite',
}

export type IResetPasswordAction = ReturnType<typeof resetPassword>;

export type ISignInWithPasswordAction = ReturnType<typeof signInWithPassword>;

export type IResetPasswordErrorAction = ReturnType<ReturnType<typeof resetPasswordError>>;

export type IChangeAuthEmailAction = ReturnType<typeof changeAuthEmail>;

export type IChangeAuthEmailErrorAction = ReturnType<ReturnType<typeof changeAuthEmailError>>;

export type IChangeAuthEmailFormAction = ReturnType<typeof changeAuthEmailForm>;

export type IOnAuthAction = ReturnType<ReturnType<typeof onAuth>>;

export type ISignOutAction = ReturnType<ReturnType<typeof signOut>>;

export type IRedirectAfterAuthAction = ReturnType<typeof redirectAfterAuth>;

export type ISignUpAction = ReturnType<typeof signUp>;
export type ISignUpErrorAction = ReturnType<typeof signUpError>;
export type ISignInErrorAction = ReturnType<ReturnType<typeof signInError>>;
