/**
 * Uses the `Intl` API to get the `date-fns` date format for the given locale.
 *
 * @param locale The locale to get the format for.
 */
export function getDateFnsFormat(locale = 'en-GB'): string {
  /**
   * Unix Epoch = January 1, 1970 12:00:00 AM
   */
  const epoch = new Date(0);
  const parts = new Intl.DateTimeFormat(locale).formatToParts(epoch).map((part) => {
    if (part.type === 'literal') {
      return part.value;
    }

    if (part.type === 'day') {
      return part.value.startsWith('0') ? 'dd' : 'd';
    }

    if (part.type === 'month') {
      return part.value.startsWith('0') ? 'MM' : 'M';
    }

    if (part.type === 'year') {
      return 'y'.repeat(part.value.length);
    }

    throw new Error(`Unhandled DateTime part type: ${part.type}`);
  });

  return parts.join('');
}
