import produce from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { AppsActionTypes, SetInstagramDataAction } from 'lib/apps/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { AppsState } from './types';

const initialState: AppsState = {};

const reducers: IReducersImmer<AppsState> = (draft) => ({
  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => initialState,

  [AppsActionTypes.SET_INSTAGRAM_DATA]: ({ payload }: SetInstagramDataAction) => {
    draft.instagram = {
      createdAt: payload?.createdAt,
    };
  },
});

const reducer = (state: AppsState = initialState, action: Action): AppsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
