import {
  deleteScheduledFollowUp,
  editEnquiryInfo,
  editEnquiryInfoSuccess,
  fetchEnquiries,
  fetchEnquiriesSuccess,
  fetchEnquiry,
  fetchEnquirySuccess,
  scheduleFollowUpMessage,
  setEnquiryProgress,
  setEnquiryStatus,
  setOwnerFilter,
  updateEnquiryOwner,
  updateEnquiryScheduledFollowUp,
  updateEnquiryWidgetSettings,
  updateNotesCount,
  updateNotesCountSuccess,
} from './actions';

export enum EnquiriesActionTypes {
  ADDED_NOTE_TO_ENQUIRY_ANALYTICS = '[Analytics] Enquiry added note',
  CHANGED_ENQUIRY_STATUS_ON_CMS_ANALYTICS = '[Analytics] Enquiry changed status',
  EDIT_ENQUIRY_INFO = '[Enquiries] Edit enquiry info',
  EDIT_ENQUIRY_INFO_SUCCESS = '[Enquiries] Edit enquiry info success',
  ENQUIRY_PROGRESS_UPDATE_ANALYTICS = '[Analytics] Enquiry progress update',
  ENQUIRY_READ_ANALYTICS = '[Analytics] Enquiry read',
  EXPORT_ENQUIRIES_CSV = '[Enquiries] Export CSV',
  FETCH_ENQUIRIES = '[Enquiries] Fetch enquiries',
  FETCH_ENQUIRIES_SUCCESS = '[Enquiries] Fetch enquiries success',
  FETCH_ENQUIRY = '[Enquiries] Fetch enquiry',
  FETCH_ENQUIRY_START = '[Enquiries] Fetch enquiry start',
  FETCH_ENQUIRY_SUCCESS = '[Enquiries] Fetch enquiry success',
  SELECTED_ENQUIRY_OWNER_ANALYTICS = '[Analytics] Enquiry selected owner',
  SET_ENQUIRY_PROGRESS = '[Enquiries] Set enquiry progress',
  SET_ENQUIRY_PROGRESS_SUCCESS = '[Enquiries] Set enquiry progress success',
  SET_ENQUIRY_STATUS = '[Enquiries] Set enquiry status',
  SET_ENQUIRY_STATUS_SUCCESS = '[Enquiries] Set enquiry status success',
  SET_OWNER_FILTER = '[Enquiries] Set owner filter',
  SWITCHED_ENQUIRY_VIEW_ANALYTICS = '[Analytics] Switched enquiry view',
  UPDATE_ENQUIRY_WIDGET_SETTINGS = '[Enquiries] Update enquiry widget settings',
  UPDATE_NOTES_COUNT = '[Enquiries] Update notes count',
  UPDATE_NOTES_COUNT_SUCCESS = '[Enquiries] Update notes count success',
  UPDATE_OWNER = '[Enquiries] Update owner',
  CLICKED_ENQUIRED_CAROUSEL_ANALYTICS = '[Analytics] Clicked enquired carousel',
  SCHEDULE_FOLLOW_UP_MESSAGE = '[Enquiries] Schedule follow-up message',
  DELETE_SCHEDULED_FOLLOW_UP = '[Enquiries] Delete scheduled follow-up',
  UPDATE_ENQUIRY_SCHEDULED_FOLLOW_UP = '[Enquiries] Update enquiry scheduled follow-up',
  RESET_BOOKED_BY_COUPLE_UNACKNOWLEDGED = '[Enquiries] Reset booked by couple unacknowledged',
}

export type IFetchEnquiry = ReturnType<typeof fetchEnquiry>;
export type IFetchEnquirySuccess = ReturnType<ReturnType<typeof fetchEnquirySuccess>>;
export type IFetchEnquiries = ReturnType<typeof fetchEnquiries>;
export type IFetchEnquiriesSuccess = ReturnType<typeof fetchEnquiriesSuccess>;
export type ISetEnquiryStatus = ReturnType<ReturnType<typeof setEnquiryStatus>>;
export type ISetEnquiryProgress = ReturnType<ReturnType<typeof setEnquiryProgress>>;
export type IUpdateNotesCount = ReturnType<typeof updateNotesCount>;
export type IUpdateNotesCountSuccess = ReturnType<typeof updateNotesCountSuccess>;
export type IUpdateEnquiryOwner = ReturnType<typeof updateEnquiryOwner>;
export type ISetOwnerFilter = ReturnType<typeof setOwnerFilter>;
export type IUpdateEnquiryWidgetSettings = ReturnType<typeof updateEnquiryWidgetSettings>;
export type IEditEnquiryInfoAction = ReturnType<typeof editEnquiryInfo>;
export type IEditEnquiryInfoSuccessAction = ReturnType<typeof editEnquiryInfoSuccess>;
export type IScheduleFollowUpMessage = ReturnType<typeof scheduleFollowUpMessage>;
export type IDeleteScheduledFollowUp = ReturnType<typeof deleteScheduledFollowUp>;
export type IUpdateEnquiryScheduledFollowUp = ReturnType<typeof updateEnquiryScheduledFollowUp>;
