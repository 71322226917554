import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { LostLeadsStats } from '@bridebook/toolbox/src/supplier/lost-leads-stats/types';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { appError } from 'lib/app/actions';
import {
  IFetchLostLeadsStatsAction,
  LostLeadsStatsActionTypes,
} from 'lib/lost-leads-stats/action-types';
import { fetchLostLeadsStatsSuccess } from 'lib/lost-leads-stats/actions';
import { IEpicDeps } from 'lib/types';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const fetchLostLeadsStatsEpic = (
  action$: Observable<IFetchLostLeadsStatsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS),
    withSupplierId(state$),
    mergeMap(([supplierId]) => {
      const getPromise = async () => {
        const res = await authenticatedFetch<LostLeadsStats>(
          ApiEndpoint.lostLeadsStats(supplierId),
        );

        if (res.status !== 200) {
          throw new Error(`Error when fetching lost leads stats for supplier id ${supplierId}`);
        }

        const response = await res.json();
        return response;
      };

      return from(getPromise()).pipe(
        map((response) => fetchLostLeadsStatsSuccess(response)),
        catchError((error) =>
          of(appError({ error, feature: LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS })),
        ),
      );
    }),
  );
