import { colors } from '@bridebook/ui';

interface ISeriesData {
  y?: number;
  color?: string;
  name: string;
  legendIndex: number;
}

interface ISeries {
  name: string;
  colorByPoint: boolean;
  data: ISeriesData[];
}

export interface ICoupleBudgets {
  chart: {
    plotBackgroundColor: string | null;
    plotBorderWidth: string | null;
    plotShadow: boolean;
    type: string;
    width: number;
  };
  title: {
    text: string;
  };
  tooltip: {
    outside: boolean;
    pointFormat: string;
  };
  plotOptions: {
    pie: {
      allowPointSelect: boolean;
      cursor: string;
      dataLabels: {
        enabled: boolean;
      };
      showInLegend: boolean;
      colors: string[];
    };
  };
  legend: {
    enabled: false;
  };
  series: ISeries[];
  credits: { enabled: boolean };
  loaded: boolean;
}

export const CoupleBudgets: ICoupleBudgets = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  title: {
    text: '',
  },
  tooltip: {
    outside: true,
    pointFormat: '<b>{point.percentage:.0f}%</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      colors: [
        colors.indigoCrush60,
        colors.teal120,
        colors.blushTangerine120,
        colors.peachRose120,
        colors.purple,
        colors.blushTangerine,
      ],
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: 'Budget',
      colorByPoint: true,
      data: [],
    },
  ],
  credits: { enabled: false },
};

export default CoupleBudgets;
