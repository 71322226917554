import { getI18n } from 'react-i18next';
import { TABS_CHART_HEIGHT } from '../constants';
import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const getProfileViews = (): IStatisticsHighchartsConfig => {
  const i18n = getI18n();
  return getChartView({
    xAxisTitle: i18n.t('enquiryGraphs:config.profileViews.xAxisTitle'),
    yAxisTitle: i18n.t('enquiryGraphs:config.profileViews.yAxisTitle'),
    seriesName: i18n.t('enquiryGraphs:config.profileViews.seriesName'),
    height: TABS_CHART_HEIGHT,
    allowDecimals: false,
    // tickInterval: 250, // 50 for weekly // was not working as expected, need to revisit
  });
};

export default getProfileViews;
