import produce from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import type { Action, IReducersImmer } from 'lib/types';
import {
  AuthActionTypes,
  type IChangeAuthEmailErrorAction,
  type IChangeAuthEmailFormAction,
  type IResetPasswordErrorAction,
  type ISignInErrorAction,
  type ISignUpErrorAction,
} from './action-types';
import ChangeAuthEmailForm from './changeAuthEmailForm';
import CollaboratorInvite from './collaborator-invite';
import Form from './form';
import type { IAuthState, ICollaboratorInviteType } from './types';

const initialState: IAuthState = {
  form: Form,
  changeAuthEmailForm: ChangeAuthEmailForm,
  collaboratorInvite: CollaboratorInvite,
  editorInitials: null,
  isAdmin: false,
  adminACLLoaded: false,
};

const reducers: IReducersImmer<IAuthState> = (draft) => ({
  [AccessControlActionTypes.UPDATE_ACCESS_CONTROL_SUCCESS]: () => {
    draft.collaboratorInvite = CollaboratorInvite;
  },

  [AuthActionTypes.SIGN_UP]: () => {
    draft.form.error = null;
    draft.form.disabled = true;
  },

  [AuthActionTypes.SIGN_IN_WITH_PASSWORD]: () => {
    draft.form.error = null;
    draft.form.disabled = true;
  },

  [AuthActionTypes.SIGN_IN_ERROR]: (action: ISignInErrorAction) => {
    draft.form.error = action.payload.error;
    draft.form.disabled = false;
  },

  [AuthActionTypes.SIGN_UP_ERROR]: (action: ISignUpErrorAction) => {
    draft.form.error = action.payload.error;
    draft.form.disabled = false;
  },

  [AuthActionTypes.RESET_PASSWORD_ERROR]: (action: IResetPasswordErrorAction) => {
    draft.form.error = action.payload.error;
  },

  [AuthActionTypes.SET_AUTH_EMAIL_FORM_FIELD]: (action: IChangeAuthEmailFormAction) => {
    const fieldName = action.payload.name;
    if (fieldName === 'password' || fieldName === 'email') {
      draft.changeAuthEmailForm.fields[fieldName] = action.payload.value;
    }
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL]: () => {
    draft.changeAuthEmailForm.disabled = true;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL_ERROR]: (action: IChangeAuthEmailErrorAction) => {
    draft.changeAuthEmailForm.disabled = false;
    draft.changeAuthEmailForm.error = action.payload;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL_SUCCESS]: () => {
    draft.changeAuthEmailForm = ChangeAuthEmailForm;
  },

  [AccessControlActionTypes.SET_ADMIN]: (action: { payload: boolean }) => {
    draft.isAdmin = action.payload;
    draft.adminACLLoaded = true;
  },

  [AuthActionTypes.DECODE_COLLABORATOR_INVITE]: (action: { payload: ICollaboratorInviteType }) => {
    draft.collaboratorInvite = action.payload;
  },
});

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/

const reducer = (state: IAuthState = initialState, action: Action): IAuthState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
