import { omit } from 'ramda';
import { IFilterLabelProps } from '@bridebook/analytics';
import { mapProgressArrayToAnalyticsObject } from '@bridebook/toolbox/src/getEnquiryProgress';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import serverSideTrack, { type IServerSideTrack } from 'lib/analytics-utils/server-side-track';
import {
  addedNoteToEnquiryAnalytics,
  clickedEnquiredCarouselAnalytics,
  selectedEnquiryOwnerAnalytics,
} from 'lib/enquiries/actions';
import { getOwnerFilterValue } from 'lib/enquiries/utils';
import { getIsEnquiryLocked } from 'lib/pay-per-enquiry/selectors';
import { getSupplierEmail } from 'lib/supplier/selectors';
import { Action, IApplicationState } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { EnquiriesActionTypes } from './action-types';
import { ISupplierWeddingManager } from './types';

interface SupplierEnquiryPropertiesGeneric {
  supplierEnquiryId: string;
  supplierEnquiryUserId: string;
  supplierEnquiryWeddingId: string;
  supplierEnquiryTime: any;
  supplierEnquiryMessage: string;
  supplierEnquiryStatus: object;
  supplierEnquiryProgress: object;
  supplierEnquiryImportant: boolean;
  supplierEnquiryRevealed: boolean;
  supplierEnquiryNotes: string;
  budgetMatch: boolean;
  isLocked?: boolean;
}

interface SupplierEnquiryPropertiesGenericInput {
  enquiry: ISupplierWeddingManager;
  statusChange?: string;
  isLocked?: boolean;
}

export const supplierEnquiryPropertiesGeneric = ({
  enquiry,
  statusChange,
  isLocked,
}: SupplierEnquiryPropertiesGenericInput): SupplierEnquiryPropertiesGeneric => {
  const {
    id,
    user: userId,
    wedding: profileId,
    createdAt,
    message,
    progress,
    //TODO: clarify if there are important and notes, maybe dropped
    important,
    notes,
    flags,
    budgetMatch,
  } = enquiry;

  const isBookedStatusChange = statusChange && statusChange === 'booked';
  const isDismissedStatusChange = statusChange && statusChange === 'dismissed';
  const revealed = Boolean(flags?.revealed);
  const mappedProgress = mapProgressArrayToAnalyticsObject(progress);
  //TODO: clarify what "open" status is
  const lostStatus = Boolean(flags?.dismissed);
  const bookedStatus = Boolean(progress?.includes?.('booked'));
  const isOpen = Boolean((lostStatus && !bookedStatus) || (!lostStatus && bookedStatus));
  const status = {
    lost: isOpen ? false : isDismissedStatusChange ? !lostStatus : lostStatus,
    booked: isOpen ? false : isBookedStatusChange ? !bookedStatus : bookedStatus,
    open: Boolean((lostStatus && !bookedStatus) || (!lostStatus && bookedStatus)),
    locked: isLocked,
  };

  return {
    supplierEnquiryId: id,
    supplierEnquiryUserId: userId,
    supplierEnquiryWeddingId: profileId,
    supplierEnquiryTime: createdAt,
    supplierEnquiryMessage: message,
    supplierEnquiryStatus: status,
    supplierEnquiryProgress: mappedProgress || {},
    supplierEnquiryImportant: important,
    supplierEnquiryRevealed: revealed,
    supplierEnquiryNotes: notes,
    budgetMatch,
  };
};

const filterLabelProps: IFilterLabelProps = (props) =>
  omit(['supplierEnquiryMessage', 'supplierEnquiryNotes'], props);

export default function enquiriesAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('CMS', filterLabelProps);

  switch (action.type) {
    case 'ENQUIRY_CLICK_ANALYTICS': {
      const { enquiry, sourceLocation } = action.payload;
      // Enquiry is not always available, e.g. when clicking on the 'View enquiry' button from Your Bookings
      const isLocked = enquiry && getIsEnquiryLocked(getState(), enquiry);

      track({
        event: 'Supplier clicked to view enquiry details on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...(enquiry && { ...supplierEnquiryPropertiesGeneric({ enquiry, isLocked }) }),
        sourceLocation,
      });
      break;
    }

    case 'ENQUIRY_REVEALED_CONTACTS_ANALYTICS': {
      const { enquiry, field } = action.payload;

      track({
        event: 'Revealed enquiry contact details on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry }),
        supplierEnquiryContactRevealed: field,
      });
      break;
    }

    case EnquiriesActionTypes.ENQUIRY_READ_ANALYTICS: {
      const { enquiry } = action.payload;
      const state = getState();
      const userEmail = state.supplier.supplier?.contacts?.email;

      const props: IServerSideTrack = {
        state,
        event: 'Read enquiry on CMS',
        category: 'CMS',
        specificEventProps: {
          ...supplierPropertiesGeneric(getState()),
          ...supplierEnquiryPropertiesGeneric({ enquiry }),
        },
      };

      if (userEmail) props.identifyProps = { userEmail };

      serverSideTrack(props);
      break;
    }

    case EnquiriesActionTypes.ENQUIRY_PROGRESS_UPDATE_ANALYTICS: {
      const { enquiry, progress, location } = action.payload;

      track({
        event: 'Updated enquiry progress on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry }),
        supplierEnquiryProgressUpdated: progress,
        supplierEnquiryUpdateLocation: location || '',
      });
      break;
    }

    case 'ENQUIRIES_EXPORT_ANALYTICS': {
      track({
        event: 'Exported supplier enquiries on CMS',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }

    case EnquiriesActionTypes.CHANGED_ENQUIRY_STATUS_ON_CMS_ANALYTICS: {
      const { enquiry, status, location, method = 'enquiryStatus' } = action.payload || {};
      const isUnbooked = status === 'booked' && enquiry.progress.booked;

      track({
        event: 'Changed enquiry status on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry, statusChange: status }),
        enquiryStatus: isUnbooked ? 'open' : status,
        supplierEnquiryUpdateLocation: location || '',
        changedStatusMethod: method || '',
      });
      break;
    }

    case EnquiriesActionTypes.SWITCHED_ENQUIRY_VIEW_ANALYTICS: {
      const {
        enquiries: { status, ownerFilter },
      } = getState();

      track({
        event: 'Switched enquiry log view on CMS',
        ...supplierPropertiesGeneric(getState()),
        toggle: status,
        ownerFilter: getOwnerFilterValue(ownerFilter),
      });
      break;
    }

    case 'CLICKED_MAILTO_LINK_ANALYTICS': {
      const { enquiry } = action.payload || {};

      track({
        event: 'Supplier clicked mailto link',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry }),
      });
      break;
    }

    case 'COPIED_CONTACT_DETAILS_ANALYTICS': {
      const { enquiry, type } = action.payload || {};

      track({
        event: 'Supplier clicked to copy contact details',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry }),
        copiedEnquiryContactDetailsType: type || '',
      });
      break;
    }

    case 'CLICKED_NEXT_ENQUIRY_ANALYTICS': {
      const { enquiry } = action.payload || {};
      const isLocked = getIsEnquiryLocked(getState(), enquiry);

      track({
        event: 'Supplier clicked to next enquiry',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry, isLocked }),
      });
      break;
    }

    case EnquiriesActionTypes.ADDED_NOTE_TO_ENQUIRY_ANALYTICS: {
      const {
        enquiries: { enquiry },
      } = getState();

      const {
        payload: { noteType },
      } = action as ReturnType<typeof addedNoteToEnquiryAnalytics>;

      track({
        event: 'Supplier added note to enquiry',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry }),
        noteType,
      });
      break;
    }

    case EnquiriesActionTypes.SELECTED_ENQUIRY_OWNER_ANALYTICS: {
      const {
        enquiries: { list, enquiry },
      } = getState();

      const {
        payload: { ownerId, enquiryId },
      } = action as ReturnType<typeof selectedEnquiryOwnerAnalytics>;

      const updatedEnquiry =
        enquiry?.id === enquiryId
          ? enquiry
          : list.find((enq) => enq.id === enquiryId) || ({} as ISupplierWeddingManager);

      track({
        event: 'Supplier assigned enquiry owner',
        ...supplierPropertiesGeneric(getState()),
        ...supplierEnquiryPropertiesGeneric({ enquiry: updatedEnquiry }),
        enquiryOwner: ownerId,
      });
      break;
    }

    case EnquiriesActionTypes.CLICKED_ENQUIRED_CAROUSEL_ANALYTICS: {
      const state = getState();
      const {
        enquiries: { enquiry },
      } = state;

      const {
        payload: { otherVenueSupplierId },
      } = action as ReturnType<typeof clickedEnquiredCarouselAnalytics>;

      track({
        event: 'Clicked Other enquired to venue - CMS',
        ...supplierPropertiesGeneric(getState()),
        ...(enquiry && supplierEnquiryPropertiesGeneric({ enquiry })),
        otherEnquiredToVenueSupplierId: otherVenueSupplierId,
        supplierEmail: getSupplierEmail(state),
      });
      break;
    }
  }
}
