import createCachedSelector from 're-reselect';
import { getI18n } from 'react-i18next';
import { createSelector } from 'reselect';
import { getCoupleNames } from '@bridebook/toolbox/src/inbox/utils';
import type { IEnquiryEdit, IScheduledFollowUp } from 'lib/enquiries/types';
import { env } from 'lib/env';
import { getFollowUpMessages } from 'lib/follow-up-messages/selectors';
import type { IApplicationState } from 'lib/types';
import {
  type TEnquiryWidgetOption,
  enquiryWidgetSectionHeights,
  enquiryWidgetUrlProps,
  getEnquiryWidgetSettingsLabels,
} from './enquiry-widget-settings-default';
import { getEnquiryWidgetLocale } from './utils';

const _enquiryWidgetSettings = (state: IApplicationState) => state.enquiries.enquiryWidgetSettings;
const _publicId = (state: IApplicationState) => state.supplier.supplier?.publicId || '';
const _enquiries = (state: IApplicationState) => state.enquiries;
const _enquiriesList = (state: IApplicationState) => state.enquiries.list;
export const getWeddingSummary = (state: IApplicationState) => state.enquiries.weddingSummary;
export const getEnquiry = createSelector(_enquiries, (enquiries) => enquiries.enquiry);

export const getWeddingSummarySuppliers = createSelector(
  [getWeddingSummary],
  (weddingSummary) => weddingSummary?.suppliers || [],
);

export const getWeddingKeywords = createSelector(
  [getWeddingSummary],
  (weddingSummary) => weddingSummary?.weddingKeywords || [],
);

export const getWeddingSummaryVenueHomeAreas = createSelector(
  [getWeddingSummary],
  (weddingSummary) => weddingSummary?.venueHomeAreas || [],
);

export const getWeddingSummaryBudgetRange = createSelector(
  [getWeddingSummary],
  (weddingSummary) =>
    weddingSummary?.budgetRangeLabel || getI18n().t('enquiryManager:undecided', 'Undecided'),
);

export const getWeddingSummaryVenueTags = createSelector(
  [getWeddingSummary],
  (weddingSummary) => weddingSummary?.venueTags || [],
);

export const getEnquiryWidgetSettingsList = createSelector(
  [_enquiryWidgetSettings],
  (enquiryWidgetSettings) =>
    Object.keys(enquiryWidgetSettings).map((item) => ({
      value: enquiryWidgetSettings[item as TEnquiryWidgetOption],
      label: getEnquiryWidgetSettingsLabels()[item as TEnquiryWidgetOption],
      name: item,
    })),
);

export const getEnquiryWidgetHeight = createSelector(
  [_enquiryWidgetSettings],
  (enquiryWidgetSettings) => {
    let widgetHeight = 270;

    Object.keys(enquiryWidgetSettings).map((item) => {
      if (enquiryWidgetSettings[item as TEnquiryWidgetOption]) {
        widgetHeight += enquiryWidgetSectionHeights[item as TEnquiryWidgetOption];
      }
    });

    // dont reserve extra space for datepicker if message field is active
    if (enquiryWidgetSettings.message && enquiryWidgetSettings.weddingDate) {
      widgetHeight -= 70;
    }

    return `${widgetHeight}px`;
  },
);

export const getEnquiryWidgetUrl = createSelector(
  [_enquiryWidgetSettings, _publicId],
  (enquiryWidgetSettings, publicId) => {
    const locale = getEnquiryWidgetLocale();
    const localePrefix = locale ? `/${locale}` : '';

    let widgetUrl: string = `${env.ENQUIRY_WIDGET_URL}${localePrefix}?publicId=${publicId}`;

    Object.keys(enquiryWidgetSettings).map((item) => {
      if (enquiryWidgetSettings[item as TEnquiryWidgetOption]) {
        widgetUrl += `&fields=${enquiryWidgetUrlProps[item as TEnquiryWidgetOption]}`;
      }
    });

    return widgetUrl;
  },
);

export const getIsFromWidgetEnquiry = createSelector(
  [getEnquiry],
  (enquiry) => enquiry?.source === 'widget',
);

export const getEnquiryEditDefaults = createSelector(
  [getEnquiry],
  (enquiry): IEnquiryEdit => ({
    email: enquiry?.email || '',
    phone: enquiry?.phone || '',
    partnerName1: enquiry?.partnerName1 || '',
    partnerName2: enquiry?.partnerName2 || '',
    guestsInitialTarget: enquiry?.guestsInitialTarget || undefined,
    weddingDate: enquiry?.weddingDate || '',
  }),
);

export const getEnquirySource = createSelector([getEnquiry], (enquiry) => enquiry?.source);
export const getAreEnquiriesLoaded = createSelector(_enquiries, ({ loaded }) => loaded);

/**
 * Returns full enquiry object, either from a single enquiry or from enquiries list
 */
export const findEnquiryById = createCachedSelector(
  getEnquiry,
  _enquiriesList,
  (_: IApplicationState, enquiryId: string) => enquiryId,
  (enquiry, enquiriesList, enquiryId) => {
    if (enquiry?.id === enquiryId) {
      return enquiry;
    }
    return enquiriesList.find((e) => e.id === enquiryId);
  },
)((_: IApplicationState, enquiryId) => enquiryId);

export const getUnreadEnquiriesCount = createSelector(
  _enquiries,
  ({ numStatuses }) => numStatuses?.unread ?? 0,
);

export const getUnacknowledgedBookingsCount = createSelector(
  _enquiries,
  ({ numStatuses }) => numStatuses?.bookedByCoupleUnacknowledged ?? 0,
);

export const getEnquiryCoupleNames = createSelector(getEnquiry, (enquiry) => {
  const names = enquiry ? [enquiry.partnerName1, enquiry.partnerName2] : [];
  return getCoupleNames(names);
});

export const getEnquiryGuestsAmount = createSelector(
  getEnquiry,
  (enquiry) => enquiry?.guestsInitialTarget,
);

export const getEnquiryScheduledFollowUp = createSelector(
  [getEnquiry, getFollowUpMessages],
  (enquiry, followUpMessages): IScheduledFollowUp | null => {
    const followUpAt = enquiry?.followUpAt;
    // Get first scheduled follow-up
    const followUpId = enquiry?.followUps?.[0];
    const followUpMessage = followUpMessages.find(
      (followUpMessage) => followUpMessage.id === followUpId,
    );
    return enquiry && followUpAt && followUpMessage
      ? {
          followUpAt,
          id: followUpMessage.id,
          title: followUpMessage.title,
        }
      : null;
  },
);

export const getEnquiriesOwnerFilter = createSelector(_enquiries, ({ ownerFilter }) => ownerFilter);
export const getEnquiriesNumStatusesFilter = createSelector(
  _enquiries,
  ({ numStatuses }) => numStatuses.filtered,
);
