import Router from 'next/router';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { isUserAnAdmin } from 'lib/access-control/utils/is-admin';
import { AuthActionTypes, IRedirectAfterAuthAction } from 'lib/auth/action-types';
import { redirectAfterAuthSuccess } from 'lib/auth/actions';
import { IEpic } from 'lib/types';
import { getHomePageUrl } from 'lib/ui/selectors';
import { UrlHelper } from 'lib/url-helper';
import { RecentSupplierStorage } from '../utils/admin-recent-supplier-storage';

export const redirectAfterAuthEpic: IEpic<IRedirectAfterAuthAction> = (action$, { state$ }) =>
  action$.pipe(
    ofType(AuthActionTypes.REDIRECT_AFTER_AUTH),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { userId },
        },
        state,
      ]) => {
        const { pathname, query } = Router;

        // There are also redirects in: `lib/auth/utils/use-logged-in-redirect.ts`
        const getPromise = async () => {
          if (!userId) return false;
          if (query && query.next) {
            await Router.push(`/${query.next}`);
            return true;
          } else if (
            pathname === '/' ||
            pathname.startsWith(UrlHelper.auth.login) ||
            pathname.startsWith(UrlHelper.auth.invite().href)
          ) {
            const isAdmin = await isUserAnAdmin(userId);
            const homepageUrl = getHomePageUrl(state);
            const recentSupplierId = RecentSupplierStorage.get();

            if (isAdmin && !recentSupplierId) {
              await Router.push(UrlHelper.admin.search);
            } else {
              await Router.push(homepageUrl);
            }
          }
        };

        return from(getPromise()).pipe(mergeMap(() => of(redirectAfterAuthSuccess())));
      },
    ),
  );

redirectAfterAuthEpic.epicName = 'redirectAfterAuthEpic';
