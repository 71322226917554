import { createSelector } from 'reselect';
import { countriesWithSupplierPro } from '@bridebook/toolbox/src/i18n/features';
import { getHomeAreaBySupplier } from '@bridebook/toolbox/src/search-suppliers/get-home-area';
import { getSupplierCountryCode } from 'lib/app/selectors';
import { isPaidTier } from 'lib/premium/utils/tiers';
import { getIsVenue, getSupplier, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getUrlHelperLandings } from 'lib/url-helper-landings';
import { getHubspotId } from './utils/hubspot/get-hubspot-id';

/**
 * Returns true if supplier can see SupplierPro features (locked or not)
 */
export const getIsSupplierProAvailable = createSelector(
  [getIsVenue, getSupplierCountryCode],
  (isVenue, countryCode) =>
    // Only UK && DE suppliers supported
    countriesWithSupplierPro.includes(countryCode) &&
    // Check if supported supplier type
    !isVenue,
);

/**
 * Returns true if any of the premium features from VenuePro or SupplierPro (locked or not) should
 * be visible
 */
export const getIsPremiumPromoVisible = createSelector(
  [getIsVenue, getIsSupplierProAvailable],
  (isVenue, isSupplierProAvailable) => isVenue || isSupplierProAvailable,
);

/**
 * Returns link to the pricing page based on supplier type and locale
 */
export const getPricingPageUrl = createSelector(
  [getSupplierType, getSupplierCountryCode],
  (supplierType, countryCode): string | undefined => {
    if (!supplierType || supplierType === 'group') {
      return;
    }

    if (supplierType === 'venue') {
      return getUrlHelperLandings(countryCode).pricing.venue;
    }

    return getUrlHelperLandings(countryCode).pricing.suppliers?.[supplierType];
  },
);

export const getIsHubspotCalendarAvailable = createSelector(
  [getSupplier, getIsVenue, getSupplierCountryCode, getSupplierTier],
  (supplier, isVenue, countryCode, supplierTier) => {
    if (!supplier) return false;

    // Return false if already paying - should be handled by specific Account Executive instead
    if (isPaidTier(supplierTier)) return false;

    const homeArea = getHomeAreaBySupplier(supplier);
    const meetingId = getHubspotId({
      countryCode,
      region: homeArea,
      isVenue,
    });

    // Return true if Hubspot meeting ID is available
    return Boolean(meetingId);
  },
);
