export enum HubspotIds {
  Test = 'beth13',
  Ashley = 'ashley-lee2',
  Dylan = 'dylan-packham/bridebook-ireland',
  Katie = 'katie-jackson3',
  Ram = 'rameez-dada',
  Suraj = 'suraj-soroay',
  Ton = 'Antonia-buttigieg',
  Zahra = 'zahra-smith',
  FranceUniversal = 'stefanie-weigang/fr-sales-meeting-request',
  GermanyUniversalVenues = 'andreas-itsos/link-fur-meeting-terminvorschlg',
  GermanyUniversalSuppliers = 'josef-stratmann',
}
