import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { fetchEnquiriesPromise, getOwnerFilterValue } from 'lib/enquiries/utils';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { EnquiriesActionTypes, IFetchEnquiries } from '../action-types';
import { fetchEnquiriesSuccess } from '../actions';

export const fetchEnquiriesEpic = (action$: Observable<IFetchEnquiries>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.FETCH_ENQUIRIES, EnquiriesActionTypes.SET_OWNER_FILTER),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async () => {
        const {
          supplier: { supplier },
          enquiries: { status, ownerFilter },
        } = state;

        const { id } = supplier;
        const page = payload?.page || 0;
        const newStatus = payload?.status || status || 'inprogress';
        const newOwnerFilter = getOwnerFilterValue(ownerFilter);

        return fetchEnquiriesPromise({
          id,
          page,
          status: newStatus,
          ownerFilter: newOwnerFilter,
        });
      };

      return from(getPromise()).pipe(
        mergeMap((data) => of(fetchEnquiriesSuccess(data))),
        catchError((error: Error) =>
          of(appError({ error, feature: EnquiriesActionTypes.FETCH_ENQUIRIES })),
        ),
      );
    }),
  );
