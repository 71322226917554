/**
 * A county can be split into smaller areas, e.g Greater London into East/West London.
 * In this case smaller area is custom defined geo-json (shape/polygon).
 * If a supplier is located inside the shape, it's customArea is set to the shape name.
 *
 * Home area is a custom area that overrides home county if exists, but can be used as a county.
 * https://bridebook.atlassian.net/browse/LIVE-16471
 */
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer from '@bridebook/toolbox/src/gazetteer';

type SupplierAreas = {
  county?: string;
  customArea?: string | null;
};

export const getHomeArea = (supplierAreas: SupplierAreas): string =>
  supplierAreas?.customArea || supplierAreas?.county || '';

export const getHomeAreaBySupplier = (supplier: ISupplier): string => {
  // Infer the right county for the supplier market
  const county = gazetteer
    .getMarketByCountry(supplier.l10n.country)
    .sortAdminAreas(supplier.address.adminArea, 1)
    .shift();

  // Some suppliers have custom areas that override the county
  const customArea = supplier.customArea;

  return getHomeArea({ county, customArea });
};
