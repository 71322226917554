import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { mapS3File } from '../../utils';
import { IAddPhoto, PhotosActionTypes } from '../action-types';
import { setOrderAfterPhotoAddition } from '../actions';
import { addPhotosSuccessAnalytics } from '../analytics';
import { getPhotos } from '../selectors';

export const addPhotoEpic = (action$: Observable<IAddPhoto>, { state$, bbanalytics }: IEpicDeps) =>
  action$.pipe(
    ofType(PhotosActionTypes.ADD_PHOTO),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const photos = getPhotos(state);
      const analyticsActions: any[] = [];

      const getPromise = async () => {
        if (!activeSupplier) throw new Error('addPhotoOrderEpic: missing activeSupplier');
        const promises = payload.map(async (s3file) => {
          const photo = await mapS3File({ s3file });
          const photoId = photo.public_id;
          bbanalytics.supplier.photos.added({ photoId });
          return photo;
        });
        const s3photos = await Promise.all(promises);
        analyticsActions.push(addPhotosSuccessAnalytics(photos, s3photos));

        const save = async () => {
          const supplier = Suppliers._.getById(activeSupplier.id);
          supplier.Photos.begin();
          s3photos.forEach((photo) =>
            supplier.Photos.push().create({
              // This operation cause appearing new photo as second instead of being last one ticket: https://bridebook.atlassian.net/browse/LIVE-13405
              order: 3 + Date.now() / 10 ** 13, // 3+ because with new 1st, 2nd etc pills we want to add new photos after the 4th one
              path: `${photo.public_id}.${photo.ext}`,
              portrait: photo.portrait,
            }),
          );
          await supplier.Photos.commit();
          return [...s3photos].length;
        };
        return save();
      };

      return from(getPromise()).pipe(
        mergeMap(() => of(...[setOrderAfterPhotoAddition(), ...analyticsActions])),
        catchError((error) => of(appError({ error, feature: 'Photos' }))),
      );
    }),
  );
