import { CreditsOptions, DashStyleValue, SeriesOptionsType } from 'highcharts';
import Highcharts from 'highcharts/highstock';
import { ISupplierAdminScore } from '@bridebook/models/source/models/Suppliers/Admin/score.types';
import { PremiumTiers } from 'lib/premium/constants';
import { ICoupleBudgets } from './charts/couple-budgets';
import { ChartGranularity } from './constants';

export type TPlotLine = {
  value: number;
  dashStyle: DashStyleValue;
  width: number;
  color: string;
  label: {
    text: string;
  };
};

export interface IStatisticsHighchartsConfig extends Highcharts.Options {
  credits: CreditsOptions;
  title: {
    text: string;
  };

  legend: {
    enabled: boolean;
  };
  xAxis: {
    categories: string[];
    title?: { text: string; enabled: boolean };
    plotLines?: TPlotLine[];
  };
  yAxis: {
    title?: { text: string; enabled: boolean };
    reversed: boolean;
    min: number;
  };
  series: SeriesOptionsType[];
  loaded: boolean;
}

export interface IReviewsRating {
  count: number;
  rating: number;
}

export type TKeywordInsights = Record<string, number>;

export interface ICompetitorsData {
  ranking?: number;
  id: string;
  profileScore: number;
  responseScore: number;
  name: string;
  position: number;
  profileScoreCategory: string;
  shortlisted?: number;
}

export type StatisticsEndpoint =
  | 'couple-budgets'
  | 'competitor-list-shortlists'
  | 'keyword-insights';

export interface ISearchRanking {
  id: string;
  profileScore: number;
  responseScore: number;
  name: string;
  position: number;
  county?: string;
  searchPositionPredictions: {
    tier0: number;
    tier1: number;
    tier2: number;
    tier3: number;
  };
}

export enum SupplierScoreCategories {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Expert = 'Expert',
}

export interface IMonoqueryData {
  bookedSupplier: Record<string, number>;
  searchAppearances: Record<string, number>;
  profileViews: Record<string, number>;
  enquiryViews: Record<string, number>;
  supplierPhoneClicks: number;
  supplierWebsiteClicks: number;
  venueRexAppearences: number;
  coupleTargetingAppearences: number;
  coupleTargetingProfileViews: number;
  specialOfferViews: number;
  shortlistedSupplier: number;
}

export interface IMonoquery {
  data: IMonoqueryData;
  loaded: boolean;
}

export interface TTierChangeRecord {
  supplierId: string;
  tierChangeDate: number;
  tierAfterChange: PremiumTiers;
  tierBeforeChange: PremiumTiers;
}

export type TYearMonthDay = string;
export type TYearMonth = string;

export type TTierChangeSegmentized<T extends string> = Record<
  T,
  {
    all: TTierChangeRecord[];
    last: TTierChangeRecord;
  }
>;

export type TTierChangeByDate = TTierChangeSegmentized<TYearMonthDay>;

export type TTierChangeByMonth = TTierChangeSegmentized<TYearMonth>;

export type TTierChangeByWeek = TTierChangeSegmentized<TYearMonthDay>;

export type TTierChangeHistory =
  | {
      loaded: false;
    }
  | {
      loaded: true;
      tierChangeByDate: TTierChangeByDate;
      tierChangeByMonth: TTierChangeByMonth;
      tierChangeByWeek: TTierChangeByWeek;
    };

export interface IStatisticsState {
  charts: {
    bookedSupplier: { chart: IStatisticsHighchartsConfig; granularity: ChartGranularity };
    searchAppearances: { chart: IStatisticsHighchartsConfig; granularity: ChartGranularity };
    profileViews: { chart: IStatisticsHighchartsConfig; granularity: ChartGranularity };
    enquiryViews: { chart: IStatisticsHighchartsConfig; granularity: ChartGranularity };
  };
  monoquery: IMonoquery;
  tierChangeHistory: TTierChangeHistory;
  reviewsRating: { data: IReviewsRating; loaded: boolean };
  searchRanking: { data: ISearchRanking[]; loaded: boolean };
  coupleBudgets: ICoupleBudgets;
  competitorsData: ICompetitorsData[];
  keywordInsights: TKeywordInsights;
  score: ISupplierAdminScore;
  scoreLoaded: boolean;
  searchPositionHistory: IStatisticsHighchartsConfig;
  searchPositionHistoryCompetitors?: IStatisticsHighchartsConfig;
  ukHistoricalRankingsCompetitors?: IUKRankings;
  // Used to refresh unread items counters
  readItemsUpdatedAt: number;
  coupleTargeting: { id: string; coupleTargeting: boolean };
  coupleViews: { id: string; views: number };
  peerPerformance: IPerformance[];
}

export interface IUKRankings
  extends Record<string, { name: string; data: Record<string, number> }> {}

export interface IPerformance {
  id: string;
  name: string;
  tier: number;
  position: number;
}
