import { createSelector } from 'reselect';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getDateFnsFormat } from '@bridebook/toolbox/src/l10n/getDateFormat';
import { getIsPremiumPromoVisible } from 'lib/premium/selectors';
import { getIsGroup, getSupplierCountryCode } from 'lib/supplier/selectors';
import { IApplicationState } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { createDeepEqualSelector } from 'lib/utils/selectors';

const getUIState = (state: IApplicationState) => state.ui;
const getUIMenu = createSelector([getUIState], (ui) => ui.menu);

const _getMarketingBanner = createSelector(getUIState, (ui) => ui.marketingBanner);
export const getTotalPhotos = createSelector(getUIMenu, (menu) => menu.totalPhotos);
export const getSnackbarState = createSelector(getUIState, (ui) => ui.snackbarState);
export const getShowAdminPanel = createSelector(getUIState, (ui) => ui.showAdminPanel);

export const getMarketingBanner = createDeepEqualSelector(_getMarketingBanner, (banner) => banner);

export const getHomePageUrl = createSelector(
  [getIsPremiumPromoVisible, getIsGroup],
  (isPremium, isGroup) =>
    isPremium || isGroup ? UrlHelper.dashboard : UrlHelper.couples.enquiries.manager,
);

export const getInputDateDisplayFormat = createSelector(
  [getSupplierCountryCode],
  (supplierCountryCode) =>
    getDateFnsFormat(gazetteer.getMarketByCountry(supplierCountryCode ?? CountryCodes.GB).locale),
);
