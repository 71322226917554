import { LostLeadsStats } from '@bridebook/toolbox/src/supplier/lost-leads-stats/types';
import { LostLeadsStatsActionTypes } from './action-types';

export const fetchLostLeadsStats = {
  type: LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS,
};

export const fetchLostLeadsStatsSuccess = (lostLeadsStats: LostLeadsStats) => ({
  type: LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS_SUCCESS,
  payload: lostLeadsStats,
});
