import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  ignoreElements,
  pluck,
  skip,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SupplierActionTypes } from 'lib/supplier/action-types';
import { Action, IEpicDeps } from 'lib/types';
import { getHomePageUrl } from 'lib/ui/selectors';

/**
 * The initial emit needs to be skipped, as distinctUntilChanged will trigger the compare
 * function only when there is previous value to compare with (on initial emit there isn't).
 * Not skipping the initial emit will call the Route.replace on direct url change.
 *
 * For more information please have a look at:
 * https://bridebook.atlassian.net/browse/LIVE-14178?*focusedCommentId=54184
 */
const INITIAL_EMIT = 1;

export const redirectOnProfileSwitchEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierActionTypes.UPDATE_SUPPLIER),
    pluck('payload'),
    distinctUntilChanged((prev, curr) => prev.id === curr.id),
    skip(INITIAL_EMIT),
    withLatestFrom(state$),
    tap(([, state]) => Router.replace(getHomePageUrl(state))),
    ignoreElements(),
  );
