import { format, subMonths } from 'date-fns';
import { keys } from 'ramda';

const getTimeseriesLastMonthTotal = (timeseries: Record<string, number>): number => {
  const lastMonth = format(subMonths(new Date(), 1), 'yyyy-MM');

  let sum = 0;
  keys(timeseries || {})
    .filter((date) => format(new Date(date), 'yyyy-MM') === lastMonth)
    .forEach((key) => {
      sum += timeseries[key];
    });

  return sum;
};

export default getTimeseriesLastMonthTotal;
