import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { ISetThumbnail, PhotosActionTypes } from '../action-types';
import shiftThumbnail from '../utils/modules/shiftThumbnail';

export const setThumbnailEpic = (action$: Observable<ISetThumbnail>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PhotosActionTypes.SET_THUMBNAIL),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const getPromise = async () => {
        if (!activeSupplier) throw new Error('setThumbnailEpic: missing activeSupplier');
        const { id } = activeSupplier;

        const setThumb = () => {
          const shiftedPhotos = shiftThumbnail(values(state.photos.photos), payload.id, 'id');
          const supplier = Suppliers._.getById(id);
          supplier.Photos.begin();
          shiftedPhotos.forEach((photo, i) =>
            supplier.Photos.getById(photo.id).set({
              order: i,
            }),
          );
          return supplier.Photos.commit();
        };

        return setThumb();
      };

      return from(getPromise()).pipe(
        catchError((error) => of(appError({ error, feature: 'Photos' }))),
        ignoreElements(),
      );
    }),
  );
