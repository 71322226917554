import { ofType } from 'redux-observable';
import { Observable, merge, of } from 'rxjs';
import { catchError, first, map, repeat, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { appError } from 'lib/app/actions';
import { AppsActionTypes, InitAppsListenerAction } from 'lib/apps/action-types';
import { setInstagramData } from 'lib/apps/actions';

export const initAppsListener = (action$: Observable<InitAppsListenerAction>) =>
  action$.pipe(
    ofType(AppsActionTypes.INIT_LISTENER),
    first(),
    switchMap(({ payload: { supplierId } }) => {
      const instagram$ = Suppliers._.getById(supplierId)
        .Apps.instagram.observe()
        .pipe(map((data) => setInstagramData(serializeTimestamps(data || {}))));

      // For more apps merge their observers together here
      return merge(instagram$).pipe(
        takeUntil(action$.pipe(ofType(AppsActionTypes.STOP_LISTENER))),
        catchError((error: Error) => of(appError({ error, feature: 'Apps' }))),
      );
    }),
    repeat(),
  );
