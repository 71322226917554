import { uniq, without } from 'ramda';
import type { IFollowUp } from '@bridebook/models/source/models/Suppliers/FollowUps.types';
import type {
  IEnquiryWeddingSummary,
  ISupplierWeddingStats,
} from '@bridebook/toolbox/src/supplier-wedding-manager';
import { toggleCoupleCollaboration } from 'lib/couples/actions';
import type { ISupplierWeddingManager, ProgressValuesType } from 'lib/enquiries/types';
import { getIsEnquiryLocked } from 'lib/pay-per-enquiry/selectors';
import type { Action, IDeps } from 'lib/types';
import { EnquiriesActionTypes } from './action-types';
import type { TEnquiryWidgetOption } from './enquiry-widget-settings-default';
import type { IEnquiryEdit } from './types';

interface ISupplierWeddingAPI {
  supplierWeddings: ISupplierWeddingManager[];
  totalPages: number;
  page: number;
  stats: ISupplierWeddingStats;
}

export const fetchEnquiryStart = () => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_START,
});

export const fetchEnquiry = (eid: string) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY,
  payload: { eid },
});

export const fetchEnquiriesSuccess = (payload: ISupplierWeddingAPI) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRIES_SUCCESS,
  payload,
});

export const fetchEnquiries = (page = 0, status?: string) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRIES,
  payload: { page, status },
});

export const changedEnquiryStatusAnalytics = (
  status: string,
  enquiry: ISupplierWeddingManager,
  location: string = 'interior',
  method: string = 'enquiryStatus',
) => ({
  type: EnquiriesActionTypes.CHANGED_ENQUIRY_STATUS_ON_CMS_ANALYTICS,
  payload: { enquiry, status, location, method },
});

export const setEnquiryProgress =
  (
    progress: ProgressValuesType,
    enquiry: ISupplierWeddingManager,
    location: string,
    value: boolean,
    method: string = 'enquiryStatus',
  ) =>
  ({ dispatch }: IDeps) => {
    const newProgress = value
      ? uniq([...(enquiry?.progress || []), progress])
      : without([progress], enquiry?.progress || []);

    if (progress === 'booked') {
      dispatch(changedEnquiryStatusAnalytics(progress, enquiry, location, method));
      dispatch(
        toggleCoupleCollaboration({
          booking: { userId: enquiry.user, profileId: enquiry.wedding },
          isCollaborating: true,
        }),
      );
    } else {
      dispatch({
        type: EnquiriesActionTypes.ENQUIRY_PROGRESS_UPDATE_ANALYTICS,
        payload: { enquiry, progress, location },
      });
    }

    return {
      type: EnquiriesActionTypes.SET_ENQUIRY_PROGRESS,
      payload: { id: enquiry.id, progress, location, value, newProgress },
    };
  };

export const setEnquiryStatus =
  (
    status: string,
    enquiry: ISupplierWeddingManager,
    location: string,
    value?: boolean,
    noTracking: boolean = false,
  ) =>
  ({ dispatch }: IDeps) => {
    if (!noTracking) {
      dispatch(changedEnquiryStatusAnalytics(status, enquiry, location));
    }

    return {
      type: EnquiriesActionTypes.SET_ENQUIRY_STATUS,
      payload: { id: enquiry.id, status, location, value },
    };
  };

export const fetchEnquirySuccess =
  ({
    supplierWedding,
    next = null,
    weddingSummary,
  }: {
    supplierWedding: ISupplierWeddingManager;
    weddingSummary: IEnquiryWeddingSummary;
    next: string | null;
  }) =>
  ({ dispatch, getState }: IDeps) => {
    const isLocked = getIsEnquiryLocked(getState(), supplierWedding);
    // Set read progress and flag when enquiry opened and not locked
    if (
      !isLocked &&
      (!supplierWedding?.progress?.includes('read') || !supplierWedding?.flags?.enquiryRead)
    ) {
      dispatch(setEnquiryProgress('read', supplierWedding, 'interior', true));
      dispatch(setEnquiryStatus('enquiryRead', supplierWedding, 'interior', true));
    }

    dispatch({
      type: EnquiriesActionTypes.ENQUIRY_READ_ANALYTICS,
      payload: {
        enquiry: { ...supplierWedding, progress: [...(supplierWedding.progress || []), 'read'] },
      },
    });

    return {
      type: EnquiriesActionTypes.FETCH_ENQUIRY_SUCCESS,
      payload: { supplierWedding, next, weddingSummary },
    };
  };

export const exportCsv =
  () =>
  ({ getState }: IDeps): Action => {
    const id = getState().supplier.supplier?.id;
    window.open(`/api/enquiries/exportCSV/${id}`, '_blank');
    return { type: EnquiriesActionTypes.EXPORT_ENQUIRIES_CSV };
  };

export const addedNoteToEnquiryAnalytics = (payload: { noteType: 'comment' | 'file' }) => ({
  type: EnquiriesActionTypes.ADDED_NOTE_TO_ENQUIRY_ANALYTICS,
  payload,
});

export const updateNotesCount = (payload: { enquiryId: string; count: number }) => ({
  type: EnquiriesActionTypes.UPDATE_NOTES_COUNT,
  payload,
});

export const updateNotesCountSuccess = (count: number) => ({
  type: EnquiriesActionTypes.UPDATE_NOTES_COUNT_SUCCESS,
  payload: count,
});

export const updateEnquiryOwner = (payload: { enquiryId: string; ownerId: string | null }) => ({
  type: EnquiriesActionTypes.UPDATE_OWNER,
  payload,
});

export const selectedEnquiryOwnerAnalytics = (payload: {
  enquiryId: string;
  ownerId: string | null;
}) => ({
  type: EnquiriesActionTypes.SELECTED_ENQUIRY_OWNER_ANALYTICS,
  payload,
});

export const setOwnerFilter = (payload: { ownerFilter: string | null | undefined }) => ({
  type: EnquiriesActionTypes.SET_OWNER_FILTER,
  payload,
});

export const switchedEnquiryViewAnalytics = () => ({
  type: EnquiriesActionTypes.SWITCHED_ENQUIRY_VIEW_ANALYTICS,
});

export const updateEnquiryWidgetSettings = ({
  name,
  value,
}: {
  name: TEnquiryWidgetOption;
  value: boolean;
}) => ({
  type: EnquiriesActionTypes.UPDATE_ENQUIRY_WIDGET_SETTINGS,
  payload: { name, value },
});

export const editEnquiryInfo = (data: IEnquiryEdit) => ({
  type: EnquiriesActionTypes.EDIT_ENQUIRY_INFO,
  payload: data,
});

export const editEnquiryInfoSuccess = (data: ISupplierWeddingManager) => ({
  type: EnquiriesActionTypes.EDIT_ENQUIRY_INFO_SUCCESS,
  payload: data,
});

export const clickedEnquiredCarouselAnalytics = (payload: { otherVenueSupplierId: string }) => ({
  type: EnquiriesActionTypes.CLICKED_ENQUIRED_CAROUSEL_ANALYTICS,
  payload,
});

export const scheduleFollowUpMessage = (followUpMessage: IFollowUp) => ({
  type: EnquiriesActionTypes.SCHEDULE_FOLLOW_UP_MESSAGE,
  payload: followUpMessage,
});

export const deleteScheduledFollowUp = () => ({
  type: EnquiriesActionTypes.DELETE_SCHEDULED_FOLLOW_UP,
});

export const updateEnquiryScheduledFollowUp = (payload: {
  followUpAt?: number;
  followUps?: string[];
}) => ({
  type: EnquiriesActionTypes.UPDATE_ENQUIRY_SCHEDULED_FOLLOW_UP,
  payload,
});

export const resetBookedByCoupleUnacknowledged = () => ({
  type: EnquiriesActionTypes.RESET_BOOKED_BY_COUPLE_UNACKNOWLEDGED,
});
