import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { hubspotIdToRegions } from 'lib/premium/utils/hubspot/hubspot-regions';
import { HubspotIds } from './types';

export const HUBSPOT_BASE_URL = 'https://meetings.hubspot.com/';

const defaultHubspotIdByCountry = {
  venues: {
    [CountryCodes.GB]: HubspotIds.Dylan,
    [CountryCodes.IE]: HubspotIds.Dylan,
    [CountryCodes.DE]: HubspotIds.GermanyUniversalVenues,
    [CountryCodes.FR]: HubspotIds.FranceUniversal,
  },
  suppliers: {
    [CountryCodes.GB]: HubspotIds.Dylan,
    [CountryCodes.IE]: HubspotIds.Dylan,
    [CountryCodes.DE]: HubspotIds.GermanyUniversalSuppliers,
    [CountryCodes.FR]: HubspotIds.FranceUniversal,
  },
};

/**
 * Returns Hubspot ID for the given region (home area / county)
 */
const findHubspotIdByRegion = (region: string) =>
  Object.keys(hubspotIdToRegions).find((key) =>
    hubspotIdToRegions[key as keyof typeof hubspotIdToRegions]?.includes(region),
  ) as HubspotIds | undefined;

/**
 * Returns Hubspot ID for the given country, region and supplier type
 */
export const getHubspotId = ({
  countryCode,
  region,
  isVenue,
}: {
  countryCode: CountryCodes;
  region: string;
  isVenue: boolean;
}): HubspotIds | undefined => {
  // Venues
  if (isVenue) {
    return (
      findHubspotIdByRegion(region) ||
      defaultHubspotIdByCountry.venues[countryCode as keyof typeof defaultHubspotIdByCountry.venues]
    );
  }
  // Suppliers
  else {
    return defaultHubspotIdByCountry.suppliers[
      countryCode as keyof typeof defaultHubspotIdByCountry.suppliers
    ];
  }
};
