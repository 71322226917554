import { createSelector } from 'reselect';
import { OfferTypes } from 'lib/offers/constants';
import { combinerNum, combinerString } from 'lib/supplier/selectors/utils';
import { IApplicationState } from 'lib/types';
import { exactValueToDiscountString } from '../utils';

const offers = (state: IApplicationState) => state.offers;
const offersAll = (state: IApplicationState) => state.offers.offers;
const offersLoaded = (state: IApplicationState) => state.offers.offersLoaded;

export const getOffers = createSelector([offersAll], (offers) => offers);
export const getOffersForm = createSelector([offers], ({ form }) => form);
export const getOffersType = createSelector([offers], ({ type }) => type);
export const getOfferIdByType = createSelector(
  [getOffers, (_: IApplicationState, type: OfferTypes) => type],
  (offers, type) => offers[type]?.id,
);

export const bbDiscount = (state: IApplicationState): string => {
  const dbValue = state.offers.offers.bbDiscount?.discount || 0;

  return exactValueToDiscountString(dbValue);
};

const bbDiscountOtherDetails = (state: IApplicationState) =>
  typeof state.offers.offers.bbDiscount?.discount === 'string' // 'discount' is string for 'other' on FS offer.
    ? state.offers.offers.bbDiscount?.discount
    : '';

export const getbbDiscountOtherDetails = createSelector([bbDiscountOtherDetails], combinerString);

const bbDiscountExpiry = (state: IApplicationState) =>
  state.offers.offers.bbDiscount?.expiration
    ? new Date(state.offers.offers.bbDiscount?.expiration).valueOf() // stored as datestring in firestore
    : 0;

export const getbbDiscountExpiry = createSelector([bbDiscountExpiry], combinerNum);

export const getOffersLoaded = createSelector(offersLoaded, (offersLoaded) => offersLoaded);

export const getOffersInitialised = createSelector(offers, ({ isInitialised }) => isInitialised);

export const getOfferDiscountType = createSelector(
  getOffersForm,
  ({ discountType }) => discountType,
);
