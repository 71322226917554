import produce from 'immer';
import { LostLeadsStats } from '@bridebook/toolbox/src/supplier/lost-leads-stats/types';
import { Action, IReducersImmer } from '../types';
import { IFetchLostLeadsStatsSuccessAction, LostLeadsStatsActionTypes } from './action-types';

export interface ILostLeadsStatsState {
  stats: LostLeadsStats;
  loading: boolean;
}

const initialState: ILostLeadsStatsState = {
  stats: null,
  loading: false,
};

const reducers: IReducersImmer<ILostLeadsStatsState> = (draft) => ({
  [LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS]: () => {
    draft.loading = true;
  },

  [LostLeadsStatsActionTypes.FETCH_LOST_LEADS_STATS_SUCCESS]: (
    action: IFetchLostLeadsStatsSuccessAction,
  ) => {
    draft.stats = action.payload;
    draft.loading = false;
  },
});

const reducer = (state = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
