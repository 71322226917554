import { getI18n } from 'react-i18next';
import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const BookedSupplier = (): IStatisticsHighchartsConfig =>
  getChartView({
    seriesName: getI18n().t('common:bookedSupplier'),
  });

export default BookedSupplier;
