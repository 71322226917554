import { PremiumTiers } from '@bridebook/toolbox';
import type { Slug } from '@bridebook/toolbox/src/types';
import { StatisticsActionTypes } from 'lib/statistics/action-types';
import { getSupplierTier } from 'lib/supplier/selectors';
import { IDeps } from 'lib/types';
import {
  IMonoquery,
  IPerformance,
  IReviewsRating,
  ISearchRanking,
  IUKRankings,
  TTierChangeByDate,
  TTierChangeByMonth,
  TTierChangeByWeek,
} from '../types';

interface IFetchStatisticsValueResponse {
  name: string;
  value: any;
}

export const fetchStatisticsValueSuccess = (payload: IFetchStatisticsValueResponse) => ({
  type: StatisticsActionTypes.FETCH_STATISTICS_VALUE_SUCCESS,
  payload,
});

export const fetchStatisticsMarket = () => ({
  type: StatisticsActionTypes.FETCH_STATISTICS_MARKET,
});

export const fetchStatisticsReach = () => ({
  type: StatisticsActionTypes.FETCH_STATISTICS_REACH,
});

export const fetchSearchRanking = () => ({
  type: StatisticsActionTypes.FETCH_SEARCH_RANKING,
});

export const fetchSearchRankingSuccess = (payload: ISearchRanking[]) => ({
  type: StatisticsActionTypes.FETCH_SEARCH_RANKING_SUCCESS,
  payload,
});

export const fetchMonoquery = () => ({
  type: StatisticsActionTypes.FETCH_MONOQUERY,
});

export const fetchTierChangeHistory = () => ({
  type: StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY,
});

export const prepareCharts = (supplierTier: PremiumTiers, supplierType?: Slug) => ({
  type: StatisticsActionTypes.PREPARE_CHARTS,
  payload: { supplierTier, supplierType },
});

export const fetchMonoquerySuccess =
  (data: IMonoquery['data']) =>
  ({ getState }: IDeps) => ({
    type: StatisticsActionTypes.FETCH_MONOQUERY_SUCCESS,
    payload: { data, supplierTier: getSupplierTier(getState()) },
  });

export const fetchTierChangeHistorySuccess = (data: {
  tierChangeByDate: TTierChangeByDate;
  tierChangeByMonth: TTierChangeByMonth;
  tierChangeByWeek: TTierChangeByWeek;
}) => ({
  type: StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY_SUCCESS,
  payload: { data },
});

export const fetchReviewsRating = () => ({
  type: StatisticsActionTypes.FETCH_REVIEWS_RATING,
});

export const fetchReviewsRatingSuccess = (payload: IReviewsRating) => ({
  type: StatisticsActionTypes.FETCH_REVIEWS_RATING_SUCCESS,
  payload,
});

export const fetchHistoricRankings = () => ({
  type: StatisticsActionTypes.FETCH_HISTORIC_RANKINGS,
});

type FetchHistoricRankingsSuccessResponse = Record<string, Record<string, number>>;

export const fetchHistoricRankingsSuccess =
  (data: FetchHistoricRankingsSuccessResponse) =>
  ({ getState }: IDeps) => ({
    type: StatisticsActionTypes.FETCH_HISTORIC_RANKINGS_SUCCESS,
    payload: { data, supplierId: getState().supplier.supplier?.id },
  });

export const fetchUkCompetitorsRankings = () => ({
  type: StatisticsActionTypes.FETCH_UK_COMPETITORS_RANKINGS,
});

export const fetchUkCompetitorsRankingsSuccess = (rankings: IUKRankings) => ({
  type: StatisticsActionTypes.FETCH_UK_COMPETITORS_RANKINGS_SUCCESS,
  payload: rankings,
});

export const fetchCompetitors = () => ({ type: StatisticsActionTypes.FETCH_COMPETITORS });

export const fetchCompetitorsSuccess = (ids: Record<string, number>) => ({
  type: StatisticsActionTypes.FETCH_COMPETITORS_SUCCESS,
  payload: ids,
});

export const fetchPeerPerformance = () => ({ type: StatisticsActionTypes.FETCH_PEER_PERFORMANCE });

export const fetchPeerPerformanceSuccess = (data: IPerformance[]) => ({
  type: StatisticsActionTypes.FETCH_PEER_PERFORMANCE_SUCCESS,
  payload: data,
});
